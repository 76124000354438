<template>
    <div>
        <div>Purchase</div>
        <a-button type="primary">Button</a-button>
    </div>
</template>

<script>
export default {
data(){
    return{
        num:'',
    }
},
mounted(){
    this.num = this.$route.params.num;
    console.log('this.num', this.num);
}
};

</script>

<style scoped lang="scss">
</style>
